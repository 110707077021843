import McheatHelper from '../McheatHelper';
import { HEATER_TYPE_WITH_TANK, HEATER_TYPE_WITHOUT_TANK } from '../../constants/heaterTypes';
import { RADIATOR_HEATING_METHOD } from '../../constants/heatingMethods';
import styles from './SettingsSecondStep.module.css';

export default function SettingsSecondStep({ heat, setHeat, heatingMethod }) {
  const setWithTankHeater = () => setHeat(HEATER_TYPE_WITH_TANK);
  const setWithoutTankHeater = () =>  {
    if (heatingMethod === RADIATOR_HEATING_METHOD) {
      return;
    }
    setHeat(HEATER_TYPE_WITHOUT_TANK);
  }
  
  return (
    <>
      <div className={styles.outerCircle}>
        <div className={styles.innerCircle}>2</div>
      </div>
      <h3 className={styles.legend}>Welchen Heizungstyp wählst du?</h3>
      <div className={styles.radioBlock}>
        <div className={styles.radioItem} onClick={setWithTankHeater}>
          <div className={styles.radioBorder}>
            {heat === HEATER_TYPE_WITH_TANK
              && <div className={styles.radioChecked}></div>
            }
          </div>
          <div className={styles.inputLabel}>
            Mit Warmwasser Speicher für Heizung + Warmwasser
          </div>
        </div>
        <div
          className={heatingMethod === RADIATOR_HEATING_METHOD ?
            `${styles.radioItem} ${styles.disabled}`
            : styles.radioItem
          }
          onClick={setWithoutTankHeater}
        >
          <div className={styles.radioBorder}>
            {heat === HEATER_TYPE_WITHOUT_TANK
              && <div className={styles.radioChecked}></div>
            }
          </div>
          <div className={styles.inputLabel}>Ohne Warmwasser Speicher</div>
        </div>
        <McheatHelper />
      </div>
    </>
  )
}
