import React from 'react'
import { Link } from 'react-router-dom'

import RoundedButton from '../RoundedButton'
import { WHATSAPP_URL } from '../../constants/whatsApp'
import { useLocationContext } from '../../context/LocationContext'

import styles from './Header.module.css'

const Header = () => {
  const handleWhatsAppBtnClick = () => {
    window.open(WHATSAPP_URL, '_blank')
  }
  const context = useLocationContext();

  return (
    <header className={styles.header} id="header">
      <Link to="/" onClick={() => context.setLocation('')}>
        <a>
          <div className={styles.logo}>
            <img src="/McHeat_Logo.svg" alt="McHeat Logo" />
          </div>
        </a>
      </Link>
      <div className={styles.links}>
        <Link to="/config" onClick={() => context.setLocation('#/config')}>
          <RoundedButton type="secondary">
            Meine Heizung konfigurieren
          </RoundedButton>
        </Link>
        <RoundedButton
          type="secondary"
          withIcon="/WhatsApp_Colour.svg"
          handleClick={handleWhatsAppBtnClick}
        >
          Gespräch starten
        </RoundedButton>
      </div>
    </header>
  )
}

export default Header
