import { useRef, useState } from 'react';
import Close from '../Close';
import Loader from '../Loader';
import { CONFIG_DESCRIPTIONS } from '../../constants/configDescriptions';
import { clientReceiverMail } from '../../constants/mails';
import composeEmailSubject from '../../utils/composeEmailSubject';
import composeEmailOffer from '../../utils/composeEmailOffer';
import composeEmailToUser from '../../utils/composeEmailToUser';
import isEmailValid from '../../utils/isEmailValid';
import isNameValid from '../../utils/isNameValid';
import isPhoneValid from '../../utils/isPhoneValid';
import styles from './Form.module.css';
import { useSnackbar } from 'notistack';

export default function Form({
  closeForm,
  heatingMethod,
  heat,
  duration,
  servicePlan,
  purchasePrice,
  monthExpenses
}) {
  const [isPending, setIsPending] = useState(false);
  const vornameInput = useRef();
  const nameInput = useRef();
  const emailInput = useRef();
  const telefonnummerInput = useRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const sendEmail = async (event) => {
    event.preventDefault();
    if (isPending) {
      return;
    }
    closeSnackbar();
    let isValid = true;
    if (!isEmailValid(emailInput.current.value)) {
      isValid = false;
      enqueueSnackbar('Invalid email', { variant: 'error' });
    }
    if (!isNameValid(nameInput.current.value)) {
      isValid = false;
      enqueueSnackbar('Invalid name', { variant: 'error' });
    }
    if (!isNameValid(vornameInput.current.value)) {
      isValid = false;
      enqueueSnackbar('Invalid vorname', { variant: 'error' });
    }
    if (!isPhoneValid(telefonnummerInput.current.value)) {
      isValid = false;
      enqueueSnackbar('Invalid telefonnummer', { variant: 'error' });
    }
    if (!isValid) {
      return;
    }
    setIsPending(true);
    const offer = composeEmailOffer(
      vornameInput.current.value,
      nameInput.current.value,
      emailInput.current.value,
      telefonnummerInput.current.value,
      CONFIG_DESCRIPTIONS.heatingMethod[heatingMethod],
      CONFIG_DESCRIPTIONS.heat[heat],
      `${duration} Monate`,
      servicePlan,
      purchasePrice
    )
    const data = {
      to: emailInput.current.value,
      header: composeEmailSubject(nameInput.current.value),
      body: composeEmailToUser(
        CONFIG_DESCRIPTIONS.heatingMethod[heatingMethod],
        CONFIG_DESCRIPTIONS.heat[heat],
        `${duration} Monate`,
        servicePlan,
        purchasePrice,
        monthExpenses
      ),
      name: nameInput.current.value,
      vorname: vornameInput.current.value,
      email: emailInput.current.value,
      tel: telefonnummerInput.current.value,
      heating_method: CONFIG_DESCRIPTIONS.heatingMethod[heatingMethod],
      heater_type: CONFIG_DESCRIPTIONS.heat[heat],
      duration: `${duration} Monate`,
      service_plan: servicePlan,
      purchase_price: purchasePrice,
      offer,
      clientReceiverMail
    }

    try {
      await fetch('https://europe-west3-mcheat-d1505.cloudfunctions.net/sendEmailTest', {
        method: 'POST',
        body: JSON.stringify(data),
      })
      enqueueSnackbar('Submitted', { variant: 'success' });
      closeForm();
    } catch(error) {
      enqueueSnackbar('Unexpected error', { variant: 'error' });
    } finally {
      setIsPending(false);
    }
  }
  
  return (
    <>
    <div className={styles.line}>
        <button
            type="button"
            onClick={closeForm}
            className={styles.formClose}
        >
            <div className={styles.closeText}>close</div> <Close />
        </button>
      </div>
      <form className={styles.form}>
        {isPending && <Loader />}

        <h3 className={styles.formTitle}>Wir schicken dir die Offerte noch heute:</h3>
        <div className={styles.formInputs}>
            <label
            className={styles.formLabel}
            htmlFor="vorname"
            >
            vorname:
            </label>
            <input
            className={styles.formInput}
            type="text"
            id="vorname"
            name="vorname"
            ref={vornameInput}
            />
            <label
            className={styles.formLabel}
            htmlFor="name"
            >
            name:
            </label>
            <input
            className={styles.formInput}
            type="text"
            id="name"
            name="name"
            ref={nameInput}
            />
            <label
            className={styles.formLabel}
            htmlFor="email"
            >
            e-mail:
            </label>
            <input
            className={styles.formInput}
            type="email"
            id="email"
            name="email"
            ref={emailInput}
            />
            <label
            className={styles.formLabel}
            htmlFor="telefonnummer"
            >
            telefonnummer:
            </label>
            <input
            className={styles.formInput}
            type="text"
            id="telefonnummer"
            name="telefonnummer"
            ref={telefonnummerInput}
            />
        </div>
        <button
            onClick={sendEmail}
            type="submit"
            className={styles.formSubmit}
        >
            Und los geht&apos;s!
        </button>
        <div className={styles.formLighting}>
            <img src="/assets/images/form-lighting.svg" alt="lighting" />
        </div>
        </form>
    </>
   
  )
}
