import ReactLoading from 'react-loading';
import styles from './Loader.module.css';

export default function Loader() {
  return (
    <div className={styles.loader}>
      <ReactLoading
        type={'spin'}
        color={'red'}
        height={100}
        width={100}
      />
    </div>
  )
}
