const MONTH_EXPENSES = {
  underfloor: {
    withTank: {
      48: 678,
      60: 556,
      72: 475
    },
    withoutTank: {
      48: 603,
      60: 495,
      72: 423
    }
  },
  radiator: {
    withTank: {
      48: 877,
      60: 877,
      72: 615
    }
  }
}

const PURCHASE_PRICE = {
  underfloor: {
    withTank: '30,069',
    withoutTank: '26,744'
  },
  radiator: {
    withTank: '38,899'
  }
}

export { MONTH_EXPENSES, PURCHASE_PRICE };
