import { useState, useEffect } from 'react'
import Modal from 'react-modal'

import YoutubeEmbed from '../YoutubeEmbed'
import Close from './CloseIcon'

import styles from './Thumbnails.module.css'

const modalCustomStyles = {
  overlay: {
    backgroundColor: 'rgba(20, 20, 20, 0.9)',
    zIndex: 10000,
  },
}

const thumbnailItems = [
  {
    videoId: 'nqye02H_H6I',
    title: 'Lukas Brühwiler, Horgen',
    thumbnail: '/Fam_Bruewiler.jpg',
  },
  {
    videoId: '1wkPMUZ9vX4',
    title: 'Familie Gairatti, Thun',
    thumbnail: '/Fam_Gairatti.jpg',
  },
  {
    videoId: 'IUN664s7N-c',
    title: 'Familie Meier, Hirzel',
    thumbnail: '/Fam_Meier.jpg',
  },
]

const ThumbnailItem = ({ title, thumbnail, videoId, handleThumbnailClick }) => (
  <div className={styles.thumbnail_item}>
    <h3>{title}</h3>
    <div
      className={styles.thumbnail_wrapper}
      onClick={() => handleThumbnailClick(videoId)}
    >
      <img src={thumbnail} alt="Thumbnail" />
      <div className={styles.play_btn}>
        <div className={styles.triangle}></div>
      </div>
    </div>
  </div>
)

const Thumbnails = () => {
  const [selectedVideoId, setSelectedVideoId] = useState(null)

  useEffect(() => {
    const body = document.querySelector('body')
    body.style.overflow = !!selectedVideoId ? 'hidden' : 'auto'
  }, [selectedVideoId])

  const handleThumbnailClick = (videoId) => {
    setSelectedVideoId(videoId)
  }

  const handleModalClose = () => {
    setSelectedVideoId(null)
  }

  return (
    <section className={styles.wrapper}>
      <h2>Wir sind McHeat.</h2>
      <div className={styles.thumbnails}>
        {thumbnailItems.map((item) => (
          <ThumbnailItem
            title={item.title}
            thumbnail={item.thumbnail}
            videoId={item.videoId}
            key={item.videoId}
            handleThumbnailClick={handleThumbnailClick}
          />
        ))}
      </div>
      <div>
        <div
          style={{ display: !!selectedVideoId ? 'block' : 'none' }}
          onClick={handleModalClose}
        >
          <Close />
        </div>
        <Modal
          className={styles.modal_content}
          style={modalCustomStyles}
          isOpen={!!selectedVideoId}
          contentLabel="Mc Heat video"
          onRequestClose={handleModalClose}
        >
          <YoutubeEmbed videoId={selectedVideoId} />
        </Modal>
      </div>
    </section>
  )
}

export default Thumbnails
