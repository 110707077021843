import React from 'react'

import styles from './RoundedButton.module.css'

const RoundedButton = ({ type, withIcon, children, handleClick }) => {
  const btnType =
    type === 'secondary' ? styles.btn_secondary : styles.btn_primary

  return (
    <button className={`${styles.btn} ${btnType}`} onClick={handleClick}>
      {children}
      {!!withIcon && (
        <img className={styles.btn_icon} src={withIcon} alt="Button Icon" />
      )}
    </button>
  )
}

export default RoundedButton
