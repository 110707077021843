const CONFIG_DESCRIPTIONS = {
  heatingMethod: {
    underfloor: 'Heizungsabgabe via Bodenheizung',
    radiator: 'Heizungsabgabe via Radiatoren'
  },
  heat: {
    withTank: 'Mit Warmwasser Speicher für Heizung + Warmwasser',
    withoutTank: 'Ohne Warmwasser Speicher'
  }
}

export { CONFIG_DESCRIPTIONS };
