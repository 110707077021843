import { useRef, useState, useEffect } from 'react'

import { VIDEO_URL } from '../../../constants/banner'

import styles from './Banner.module.css'

const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase()
  return ua.indexOf('safari') > -1 && ua.indexOf('chrome') < 0
}

const Banner = () => {
  const [shouldUseImage, setShouldUseImage] = useState(false)
  const [isVideoReady, setIsVideoReady] = useState(false)

  const videoParentRef = useRef()

  useEffect(() => {
    // check if user agent is safari and we have the ref to the container <div />
    if (isSafari() && videoParentRef.current) {
      // obtain reference to the video element
      const player = videoParentRef.current.querySelector('#promoVideo')

      // if the reference to video player has been obtained
      if (player) {
        // set the video attributes using javascript as per the
        // webkit Policy
        player.controls = false
        player.playsinline = true
        player.muted = true
        player.setAttribute('muted', '') // leave no stones unturned :)
        player.autoplay = true

        // Let's wait for an event loop tick and be async.
        setTimeout(() => {
          // player.play() might return a promise but it's not guaranteed crossbrowser.
          const promise = player.play()
          // let's play safe to ensure that if we do have a promise
          if (promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                // if promise fails, hide the video and fallback to <img> tag
                videoParentRef.current.style.display = 'none'
                setShouldUseImage(true)
              })
          }
        }, 0)
      }
    }
  }, [])

  useEffect(() => {
    if (videoParentRef.current) {
      const video = videoParentRef.current.querySelector('#promoVideo')
      video.onloadeddata = () => {
        setIsVideoReady(true)
        if (!isSafari()) {
          video.play()
        }
      } 
    }
  }, [])

  const handleVideoClick = () => {
    if (videoParentRef.current) {
      const video = videoParentRef.current.querySelector('#promoVideo')
      video.muted = !video.muted
    }
  }

  return (
    <>
      <div
        className={styles.banner}
        style={{ display: isVideoReady ? 'none' : 'block' }}
      >
        <img src="/banner.png" alt="" />
      </div>

      {shouldUseImage ? (
        <img
          src={VIDEO_URL}
          alt="Promo Video"
          className={styles.banner}
          onLoad={() => setIsVideoReady(true)}
          style={{ display: isVideoReady ? 'block' : 'none' }}
        />
      ) : (
        <div
          ref={videoParentRef}
          style={{ display: isVideoReady ? 'block' : 'none' }}
          onClick={handleVideoClick}
          dangerouslySetInnerHTML={{
            __html: `
            <video
              loop
              muted
              autoplay
              playsinline
              preload="metadata"
              class="${styles.banner}"
              id="promoVideo"
            >
            <source src="${VIDEO_URL}" type="video/mp4" />
            </video>`,
          }}
        />
      )}
    </>
  )
}

export default Banner
