import { useRef, useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'

import { bubbleOffsets } from '../constants'

import styles from './Chart.module.css'

const Ticks = ({ heat }) => {
  let value = heat === 0 ? 100 : 30
  const elements = []

  while (value >= 3) {
    elements.push(
      <div className={styles.tick} key={value}>
        <div className={styles.tick_label}>{value}k</div>
      </div>
    )
    value -= heat === 0 ? 10 : 3
  }

  return elements
}

const Chart = ({
  years,
  persons,
  rangeStep,
  heat,
  savingsValue,
  handleRangeChange,
}) => {
  const [shouldUpdate, setShouldUpdate] = useState(true)

  const axisRef = useRef(null)

  useEffect(() => {
    if (shouldUpdate) setShouldUpdate(false)
  }, [shouldUpdate])

  const isXl = useMediaQuery({
    query: '(min-width: 1201px)',
  })

  const isMd = useMediaQuery({
    query: '(min-width: 769px)',
  })

  const val1 = isXl ? 22.5 : isMd ? 12.5 : 9
  const val2 = isXl ? 0.45 : isMd ? 0.25 : 0.18
  const thumbPosition = val1 - rangeStep * val2

  let bubbleHeight =
    axisRef?.current?.clientHeight * bubbleOffsets[`${persons.value}-${years.value}`]

  const bubbleWidth = bubbleHeight / 1.5

  return (
    <div className={styles.chart_wrapper}>
      <div className={styles.y_axis} ref={axisRef}>
        <Ticks heat={heat} />
        <div className={styles.tick}></div>
      </div>
      <div className={styles.range_wrap}>
        {!!axisRef?.current && (
          <output
            className={styles.bubble}
            style={{
              left: `calc(${rangeStep}% + (${thumbPosition}px))`,
              width: bubbleWidth,
              height: bubbleHeight,
              top: isMd ? -bubbleHeight : -bubbleHeight + 5,
            }}
          >
            <div className={styles.outer_circle}>
              <div className={styles.middle_circle}>
                <div className={styles.inner_circle}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="100%"
                    viewBox="0 0 800 450"
                  >
                    <text
                      x="50%"
                      y="50%"
                      textAnchor="middle"
                      alignmentBaseline="central"
                      dominantBaseline="central"
                      fontSize="150px"
                      fill="#eee"
                    >
                      <tspan x="50%" textAnchor="middle" dy="-50">
                        <>{savingsValue.toLocaleString('de-CH') + ' .'}&#8211;</>
                      </tspan>
                      <tspan x="50%" textAnchor="middle" dy="150" fontSize="100px">
                        gespart
                      </tspan>
                    </text>
                  </svg>
                </div>
              </div>
            </div>
            <div className={styles.stick}></div>
          </output>
        )}
        <input
          className={styles.range}
          type="range"
          min="0"
          max="20"
          step="5"
          value={years.value}
          onChange={handleRangeChange}
          style={{ backgroundSize: `${rangeStep}% 100%` }}
        />
        <div className={styles.range_tickmarks}>
          <div />
          <div>5 Jahre</div>
          <div>10 Jahre</div>
          <div>15 Jahre</div>
          <div>20 Jahre</div>
        </div>
      </div>
    </div>
  )
}

export default Chart
