import {
  HashRouter
} from "react-router-dom";
import HomePage from "./components/HomePage";
import ConfigPage from "./components/ConfigPage";
import Layout from "./components/Layout";
import './App.css';
import { useEffect } from "react";
import { useLocationContext } from "./context/LocationContext";
import ScrollToTop from "./hooks/scrollToTop";

function App() {
    const location = window.location;
    const context = useLocationContext()
    useEffect(() => {
    }, [context.location])
  return (
    <HashRouter>
        <ScrollToTop />
      <div>
        <Layout>
          {(location.hash === '#/config' || location.hash === '#/config#header') ? <ConfigPage /> : <HomePage />}
        </Layout>
      </div>
    </HashRouter>
  );
}

export default App;
