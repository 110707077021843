import styles from './Close.module.css';

export default function Close() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="13366.952 3475.359 22.73 22.021"
      className={styles.svg}
    >
      <g
        data-name="CompositeLayer"
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeWidth="2"
        stroke="#000"
        fill="transparent"
      >
        <path
          d="m13389.683 3475.359-22.73 22.02"
          data-name="Linie 241"
        />
        <path
          d="m13366.952 3475.359 22.731 22.02"
          data-name="Linie 240"
        />
      </g>
    </svg>
  )
}
