import React from "react";
import { Link } from "react-router-dom";

import RoundedButton from "../../RoundedButton";
import { useLocationContext } from "../../../context/LocationContext";

import styles from "./Description.module.css";

const Description = () => {
    const context = useLocationContext();
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <p className={styles.description_text}>
          Eine neue Heizung entlastet nicht nur die Umwelt, sondern auch dein
          Budget massiv. Darum macht McHeat jetzt Dampf im Schweizer
          Heizungsmarkt: Wir beraten dich zu den neusten Technologien, dann
          installieren und finanzieren wir die beste Lösung für dich. Alles geht
          schneller, günstiger und bequemer für dich als irgendwo sonst. Damit
          du noch heute aufhören kannst, dein Geld zu verheizen.
        </p>
        <Link to="/config" onClick={() => context.setLocation('#/config')}>
          <RoundedButton type="primary">
            Meine Heizung konfigurieren
          </RoundedButton>
        </Link>
      </div>

      {/* <Image
        src={Description_banner}
        alt="Description Banner"
        width={50}
        height={100}
        layout="fill"
        objectFit="contain"
        objectPosition="right"
      /> */}
      <img
        src="/description_banner.png"
        alt="Description Banner"
        className={styles.image}
      />
    </div>
  );
};

export default Description;
