import { useRef } from 'react'
import { useMediaQuery } from 'react-responsive'

import Close from './CloseIcon'
import { WHATSAPP_URL } from '../../constants/whatsApp'

import styles from './WhatsAppCard.module.css'

const defaultStyleDesktop = {
  transition: 'right 1000ms ease-in-out',
  right: '-375px',
}

const defaultStyleMobile = {
  transition: 'left 1000ms ease-in-out',
  right: '130%',
}

const transitionStylesDesktop = {
  entering: { right: '-375px' },
  entered: { right: '0px' },
  exiting: { right: '0px' },
  exited: { right: '-375px' },
}

const transitionStylesMobile = {
  entering: { right: '130%' },
  entered: { right: '50%' },
  exiting: { right: '50%' },
  exited: { right: '-50%' },
}

const WhatsAppCard = ({ transitionState, handleClose }) => {
  const time = useRef(new Date().toTimeString().slice(0, 5))

  const isMobile = useMediaQuery({
    query: '(max-width: 576px)',
  })

  const handleWhatsAppBtnClick = () => {
    handleClose()
    window.open(WHATSAPP_URL, '_blank')
  }

  return (
    <div
      className={styles.card}
      style={{
        ...(isMobile ? defaultStyleMobile : defaultStyleDesktop),
        ...(isMobile
          ? transitionStylesMobile[transitionState]
          : transitionStylesDesktop[transitionState]),
      }}
    >
      <button className={styles.card_close} onClick={handleClose}>
        close <Close />
      </button>
      <div className={styles.operator_block}>
        <img src="/operator.svg" alt="Operator image" width={75} height={75} className={styles.img} />
        <div className={styles.operator_info}>
          <p>McHeat</p>
          <p>Antwortet typischerweise innerhalb eines Tages</p>
        </div>
      </div>
      <div className={styles.msg_wrapper}>
        <div className={styles.msg_box}>
          <p className={styles.msg_person}>McHeat</p>
          <p>Hallo! &#128075;</p>
          <p className={styles.main_text}>
            Ich bin hier um zu helfen. Lass mich wissen wissen, wie ich dich bei
            deiner Heizungsumstellung unterstützen kann? Gemeinsam finden wir
            eine Lösung. &#129299;
          </p>
          <p className={styles.time}>{time.current}</p>
        </div>
      </div>
      <button className={styles.whatsapp_btn} onClick={handleWhatsAppBtnClick}>
        <img
          className={styles.btn_icon}
          src="WhatsApp_Black.svg"
          alt="WhatsApp Icon"
        />
        Start chat
      </button>
    </div>
  )
}

export default WhatsAppCard
