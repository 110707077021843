import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Controls from './Controls'
import Chart from './Chart'
import RoundedButton from '../../RoundedButton'
import {
  personsOptions,
  yearsOptions,
  gasSavings,
  oilSavings,
  MIN_RANGE_VALUE,
  MAX_RANGE_VALUE,
} from './constants'
import { useLocationContext } from '../../../context/LocationContext'
import styles from './Savings.module.css'

const getSavingsValue = (heat, persons, years) => {
  const searchPattern = `${persons.value}-${years.value}`
  return heat === 0 ? gasSavings[searchPattern] : oilSavings[searchPattern]
}

const Savings = () => {
  const [rangeStep, setRangeStep] = useState(75)

  const [persons, setPersons] = useState(personsOptions[1])
  const [years, setYears] = useState(yearsOptions[2])
  const [heat, setHeat] = useState(0)
  const context = useLocationContext();


  const handleRangeChange = (event) => {
    if (event.target.value !== '0') {
      setYears(
        yearsOptions.find((item) => item.value === Number(event.target.value))
      )
    }
  }

  useEffect(() => {
    const newStep = Number(
      ((years.value - MIN_RANGE_VALUE) * 100) /
        (MAX_RANGE_VALUE - MIN_RANGE_VALUE)
    )
    setRangeStep(newStep)
  }, [years])


  const savingsValue = getSavingsValue(heat, persons, years)

  return (
    <div className={styles.section_wrapper}>
      <div className={styles.left_column}></div>
      <div className={styles.center_column}>
        <h2>Rechne hier, was du sparst!</h2>
        <Controls
          persons={persons}
          setPersons={setPersons}
          personsOptions={personsOptions}
          years={years}
          setYears={setYears}
          yearsOptions={yearsOptions}
          heat={heat}
          setHeat={setHeat}
        />
        <Chart
          years={years}
          persons={persons}
          rangeStep={rangeStep}
          savingsValue={savingsValue}
          handleRangeChange={handleRangeChange}
          heat={heat}
        />
        <Link to="/config" onClick={() => context.setLocation('#/config')}>
          <RoundedButton type="primary">
            Meine Heizung konfigurieren
          </RoundedButton>
        </Link>
      </div>
      <div className={styles.right_column}>
        <div className={styles.banner_wrapper}>
          <img src="/Tool.svg" alt="Tool Banner" />
        </div>
      </div>
    </div>
  )
}

export default Savings
