import { useEffect, useState } from 'react';
import { SnackbarProvider } from 'notistack';
import { Link } from 'react-router-dom';
import Settings from '../Settings';
import ConfigCalculatorContainer from '../ConfigCalculatorContainer';
import { HEATER_TYPE_WITH_TANK } from '../../constants/heaterTypes';
import { FORTY_EIGHT } from '../../constants/operationYears';
import { SERVICE_PLAN_WARM } from '../../constants/servicePlans';
import { UNDERFLOOR_HEATING_METHOD } from '../../constants/heatingMethods';
import { MONTH_EXPENSES, PURCHASE_PRICE } from '../../constants/expenses'
import { useLocationContext } from '../../context/LocationContext';
import styles from './ConfigPage.module.css';

export default function ConfigPage() {
  const [heatingMethod, setHeatingMethod] = useState(UNDERFLOOR_HEATING_METHOD);
  const [heat, setHeat] = useState(HEATER_TYPE_WITH_TANK);
  const [duration, setDuration] = useState(FORTY_EIGHT);
  const [servicePlan, setServicePlan] = useState(SERVICE_PLAN_WARM);
  const [monthExpenses, setMonthExpenses] = useState(MONTH_EXPENSES[UNDERFLOOR_HEATING_METHOD][HEATER_TYPE_WITH_TANK][FORTY_EIGHT]);
  const [purchasePrice, setPurchasePrice] = useState(PURCHASE_PRICE[UNDERFLOOR_HEATING_METHOD][HEATER_TYPE_WITH_TANK]);
  const context = useLocationContext();

  useEffect(() => {
    setMonthExpenses(MONTH_EXPENSES[heatingMethod][heat][duration]);
    setPurchasePrice(PURCHASE_PRICE[heatingMethod][heat]);
  }, [heat, heatingMethod, duration]);

  return (
    <SnackbarProvider maxSnack={4}>
      <section className={styles.section}>
        <div className={styles.wrapper}>
            <Link to="/" onClick={() => context.setLocation('')}>
            <div className={styles.linkWrapper}>
              <img
                src="/assets/images/arrow-left.svg"
                alt="Back to home"
                width={25}
                height={25}
              />
              <div className={styles.home}>Home</div>
            </div>
          </Link>
          <h2 className={styles.title}>So einfach kommst du zur Offerte!</h2>
          <Settings
            heatingMethod={heatingMethod}
            setHeatingMethod={setHeatingMethod}
            heat={heat}
            setHeat={setHeat}
            duration={duration}
            setDuration={setDuration}
            servicePlan={servicePlan}
            setServicePlan={setServicePlan}
          />
          <ConfigCalculatorContainer
            monthExpenses={monthExpenses}
            purchasePrice={purchasePrice}
            heatingMethod={heatingMethod}
            heat={heat}
            duration={duration}
            servicePlan={servicePlan}
          />
        </div>
      </section>
    </SnackbarProvider>
  )
}
