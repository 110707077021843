import React from 'react'

import styles from './YoutubeEmbed.module.css'

const YoutubeEmbed = ({ videoId }) => (
  <iframe
    className={styles.video}
    src={`https://www.youtube.com/embed/${videoId}?autoplay=1&modestbranding=1&rel=0`}
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    title="Mc Heat Promo"
  />
)

export default YoutubeEmbed
