import McheatHelper from '../McheatHelper';
import CheckedCheckbox from '../CheckedCheckbox';
import { SERVICE_PLAN_WARM, SERVICE_PLAN_HOT } from '../../constants/servicePlans';
import styles from './SettingsFourthStep.module.css';

export default function SettingsFourthStep({ servicePlan, setServicePlan }) {
  const setWarmServicePlan = () => setServicePlan(SERVICE_PLAN_WARM);
  const setHotServicePlan = () => setServicePlan(SERVICE_PLAN_HOT);

  return (
    <>
      <div className={styles.outerCircle}>
        <div className={styles.mediumOuterCircle}>
          <div className={styles.mediumInnerCircle}>
            <div className={styles.innerCircle}>4</div>
          </div>
        </div>
      </div>
      <h3 className={styles.legend}>Welchen Serviceplan wählst du?</h3>
      <div className={styles.radioBlock}>
        <div className={styles.table}>
          <div className={styles.columnsBlock}>
            <div className={styles.column}>
              <div className={styles.radioItem} onClick={setWarmServicePlan}>
                <div className={styles.radioBorder}>
                  {servicePlan === SERVICE_PLAN_WARM && <div className={styles.radioChecked}></div>}
                </div>
                <div>Warm</div>
              </div>
            </div>
            <div className={styles.column}>
              <div className={styles.radioItem} onClick={setHotServicePlan}>
                <div className={styles.radioBorder}>
                  {servicePlan === SERVICE_PLAN_HOT && <div className={styles.radioChecked}></div>}
                </div>
                <div>Hot</div>
              </div>
            </div>
          </div>
          <p className={styles.tableTitle}>Ich wähle den folgenden Serviceplan:</p>
          <div className={styles.tableFeatures}>
            <div className={styles.feature}>
              <p>Kostenlose Beratung</p>
              <div className={styles.checkboxPair}>
                <div className={styles.checkboxWrapper}>
                  <CheckedCheckbox />
                </div>
                <div className={styles.checkboxWrapper}>
                  <CheckedCheckbox />
                </div>
              </div>
            </div>
            <div className={styles.feature}>
              <div>
                <p>Regelmässige Wartung</p>
                <ul> 
                  <li>– inkl. Anfahrt/Rückfahrt</li>
                  <li>– pro-aktive Terminierung</li>
                  <li>– inkl. Verbrauchsmaterial</li>
                </ul>
              </div>
              <div className={styles.checkboxPair}>
                <div className={styles.checkboxWrapper}>
                  <CheckedCheckbox />
                </div>
                <div className={styles.checkboxWrapper}>
                  <CheckedCheckbox />
                </div>
              </div>
            </div>
            <div className={styles.feature}>
              <div>
                <p>Garantiepaket McHeat</p>
                <ul> 
                  <li>– Bei Reparaturen keine Kosten</li>
                  <li>– Versicherung bei Heizungsausfall</li>
                </ul>
              </div>
              <div className={styles.checkboxPair}>
                <div className={styles.checkboxWrapper}></div>
                <div className={styles.checkboxWrapper}>
                  <CheckedCheckbox />
                </div>
              </div>
            </div>
            <div className={styles.feature}>
              <p>Online-Support 24/7, kostenlos</p>
              <div className={styles.checkboxPair}>
                <div className={styles.checkboxWrapper}></div>
                <div className={styles.checkboxWrapper}>
                  <CheckedCheckbox />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.helperWrapper}>
          <McheatHelper />
        </div>
      </div>
    </>
  )
}
