import React from 'react'

import styles from './Benefits.module.css'

const benefitsItems = [
  {
    img: '/Schneller.png',
    slogans: [
      'Alles aus einer Hand',
      'Beratung, Installation, Finanzierung',
      'Du sparst vom ersten Tag an!',
    ],
  },
  {
    img: '/Guenstiger.png',
    slogans: ['Ohne Bargeld', 'Ohne Bank', 'Du bezahlst in Raten!'],
  },
  {
    img: '/Bequemer.png',
    slogans: [
      'Abwicklung vom Sofa aus',
      'Servicepakete nach Wahl',
      'Du hast die sauberste Lösung!',
    ],
  },
]

const BenefitItem = ({ benefit }) => (
  <div className={styles.benefit_item}>
    <img src={benefit.img} alt="Benefit image" />
    <ul className={styles.slogans_list}>
      {benefit.slogans.map((item, i) => (
        <li key={i}>{item}</li>
      ))}
    </ul>
  </div>
)

const Benefits = () => (
  <section className={styles.wrapper}>
    <h2>Das spricht für McHeat!</h2>
    <div className={styles.benefits}>
      {benefitsItems.map((item, i) => (
        <BenefitItem benefit={item} key={i} />
      ))}
    </div>
  </section>
)

export default Benefits
