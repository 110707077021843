/* eslint-disable react/no-unknown-property */
import { useState } from 'react';
import McheatHelper from '../McheatHelper';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import Form from '../Form';
import styles from './ConfigCalculatorContainer.module.css';

export default function ConfigCalculatorContainer({
  monthExpenses,
  purchasePrice,
  heatingMethod,
  heat,
  duration,
  servicePlan
}) {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const openForm = () => setIsFormOpen(true);
  const closeForm = () => setIsFormOpen(false);

  return (
    <div className={styles.wrapper}>
      <style jsx>{`
        .fade-enter-active {
          animation: fade-in 500ms forwards
        }
        .fade-exit-active {
          animation: fade-out 500ms forwards
        }
        @keyframes fade-in {
          0% {
            opacity: 0;
            top: 130%;
          }
          100% {
            opacity: 1;
            top: 50%
          }
        }
        @keyframes fade-out {
          0% {
            opacity: 1;
            top: 50%;
          }
          100% {
            opacity: 0;
            top: 150%;
          }
        }
      `}</style>
      <SwitchTransition mode='out-in'>
        <CSSTransition
          key={isFormOpen}
          timeout={500}
          classNames='fade'
        >
          {isFormOpen ?
            <div className={styles.form}>
              <Form
                closeForm={closeForm}
                heatingMethod={heatingMethod}
                heat={heat}
                duration={duration}
                servicePlan={servicePlan}
                purchasePrice={purchasePrice}
                monthExpenses={monthExpenses}
              />
            </div>
            : <></>
          }
        </CSSTransition>
      </SwitchTransition>
      <div className={styles.mcHeat}></div>
      <div className={styles.phrase}>
        <img
          className={styles.phraseWrapper}
          src="/assets/images/phrase-config2.png"
          alt="phrase"
        />
        <h2 className={styles.phraseTitle}>Zack, und<br/>es geht los!</h2>
      </div>
      <div className={styles.expensesBlock}>
        <div className={styles.expensesTitle}>Deine monatlichen Kosten</div>
        <div className={styles.expenses}>CHF {monthExpenses}.-</div>
        <div>Kaufpreis: {purchasePrice}</div>
        <button
          onClick={openForm}
          className={styles.sendOffer}
        >
          Bitte schickt mir die detaillierte Offerte!
        </button>
        <McheatHelper />
      </div>
    </div>
  )
}
