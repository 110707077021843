import { WHATSAPP_URL } from "../constants/whatsApp"

export default function composeEmailToUser(
  heating_method,
  heater_type,
  duration,
  service_plan,
  purchasePrice,
  monthExpenses
) {
  return `<!doctype html>
  <html>
    <head>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
      <title>McHeat offer</title>
      <style>
        .logo {
          border: none;
          width: 162px;
          vertical-align: middle !important;
        }
        .address {
          width: 145px;
          min-width: 160px;
          float: right;
          color: black !important;
          text-decoration: none;
          line-height: 1;
          vertical-align: middle !important;
        }
        body {
          background-color: #80c0ff;
          font-family: sans-serif;
          -webkit-font-smoothing: antialiased;
          font-size: 14px;
          line-height: 1.4;
          margin: 0;
          padding: 0;
          -ms-text-size-adjust: 100%;
          -webkit-text-size-adjust: 100%;
        }

        table {
          border-collapse: separate;
          mso-table-lspace: 0pt;
          mso-table-rspace: 0pt;
          width: 100%;
        }
        table td {
          font-family: sans-serif;
          font-size: 14px;
          vertical-align: top;
        }
        .body {
          background-color: #80c0ff;
          width: 100%;
        }
        .container {
          display: block;
          margin: 0 auto !important;
          max-width: 1200px;
          width: 100%;
        }
        .header {
          justify-content: space-between;
          width: 100%;
          padding: 20px 10px 10px !important;
        }
        .content {
          box-sizing: border-box;
          display: block;
          margin: 0 auto;
          max-width: 1200px;
          width: 100%;
        }
        .main {
          background: #ffffff;
          border-radius: 3px;
          width: 100%;
        }
        .wrapper {
          box-sizing: border-box;
          padding: 20px 0 0 20px;
        }
        .content-block {
          padding-bottom: 10px;
          padding-top: 10px;
        }
        .content-block {
          margin: 30px auto 0;
        }
        .expenses-block {
          margin-top: 90px;
          padding-bottom: 20px;
          box-sizing: border-box;
        }
        .kosten {
          font-weight: bold;
          font-size: 1.2rem;
          text-align: center;
        }
        .expenses {
          font-size: 1.6rem;
          text-align: center;
        }
        .price {
          text-align: center;
        }
        .flex {
          vertical-align: bottom;
        }
        .relative {
          padding-left: 40px;
        }
        .banner {
          float: right;
          width: 210px;
          height: 370px;
          background-image: url('https://i.postimg.cc/RhytSwP9/description-banner.png');
          background-repeat: no-repeat;
          background-size: 210px auto;
          align-self: flex-end;
        }
        .help {
          text-decoration: underline;
          font-size: 1.2rem;
          font-weight: bold;
          cursor: pointer;
        }
        .footer {
          clear: both;
          margin-top: 10px;
          text-align: center;
          width: 100%;
        }
        .footer td,
        .footer p,
        .footer span,
        .footer a {
          color: black;
          font-size: 12px;
          text-align: center;
        }
        h1,
        h2,
        h3,
        h4 {
          color: #000000;
          font-family: sans-serif;
          font-weight: 400;
          line-height: 1.4;
          margin: 0;
          margin-bottom: 30px;
        }
        table {
          border-spacing: 0;
        }
        h1 {
          font-size: 35px;
          font-weight: 300;
          text-align: center;
          text-transform: capitalize;
        }
        p,
        ul,
        ol {
          font-family: sans-serif;
          font-size: 14px;
          font-weight: normal;
          margin: 0;
          margin-bottom: 15px;
        }
        p li,
        ul li,
        ol li {
          list-style-position: inside;
          margin-left: 5px;
        }
        a {
          color: black !important;
          text-decoration: none;
        }
        ul {
          padding-left: 15px;
        }
        .zero-margin {
          margin: 0;
          padding: 0;
          font-size: 14px;
          line-height: 21px;
        }
        .horizontal-div {
          width: 100%;
          height: 20px;
        }
        @media (max-width: 620px) {
          .relative,
          .flex {
            display: inline-block;
          }
          .flex {
            float: right !important;
          }
          .relative {
            padding-left: 0;
            color: black;
          }
          .expenses-block {
            margin-top: 20px;
          }
          .zero-margin {
            font-size: 6px !important;
            line-height: 18px;
          }
          .address {
            width: 100px;
          }
          .logo {
            width: 120px;
          }
        }
        @media only screen and (max-width: 620px) {
          table.body h1 {
            font-size: 28px !important;
            margin-bottom: 10px !important;
          }
          table.body p,
          table.body ul,
          table.body ol,
          table.body td,
          table.body span,
          table.body a {
            font-size: 16px !important;
          }
          table.body .wrapper,
          table.body .article {
            padding: 10px !important;
          }
          table.body .content {
            padding: 0 !important;
          }
          table.body .container {
            padding: 0 !important;
            width: 100% !important;
          }
          table.body .main {
            border-left-width: 0 !important;
            border-radius: 0 !important;
            border-right-width: 0 !important;
          }
        }
        @media all {
          .apple-link {
            color: black !important;
            font-family: inherit !important;
            font-size: inherit !important;
            font-weight: inherit !important;
            line-height: inherit !important;
            text-decoration: none !important;
          }
          #MessageViewBody a {
            color: inherit;
            text-decoration: none;
            font-size: inherit;
            font-family: inherit;
            font-weight: inherit;
            line-height: inherit;
          }
        }
      </style>
    </head>
    <body>
      <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="body">
        <tr>
          <td class="container header">
            <div class="horizontal-div"></div>
            <img class="logo" src="https://i.postimg.cc/VLSy6Y8z/Mc-Heat-Logo.png" alt="logo" />
            <div class="address">
              <p class="zero-margin">McHeat</p>
              <p class="zero-margin">c/o Roman Gaus</p>
              <p class="zero-margin">Regensdorferstr. 194,</p>
              <p class="zero-margin">8049 Zürich</p>
              <p class="zero-margin">077 215 79 99</p>
            </div>
            <div class="horizontal-div"></div>
          </td>
        </tr>
        <tr>
          <td class="container">
            <div class="content">

              <!-- START CENTERED WHITE CONTAINER -->
              <table role="presentation" class="main">

                <!-- START MAIN CONTENT AREA -->
                <tr>
                  <td class="wrapper">
                    <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                      <tr>
                        <td class="relative">
                          <p>Vielen Dank, dass Du dich für deine Finanzierungsofferte von Mc HEAT entschieden hast. Bitte setze Dich direkt mit uns in Verbindung, wenn du unser Angebot annehmen möchtest.</p>
                          <p>Deine Offerte ist für:</p>
                          <ul padding-left="5px">
                            <li>${heating_method}</li>
                            <li>${heater_type}</li>
                            <li>${duration}</li>
                            <li class="capitalize">Serviceplan: ${service_plan}</li>
                          </ul>
                          <div class="expenses-block">
                            <div class="kosten">Deine monatlichen Kosten</div>
                            <div class="expenses">CHF ${monthExpenses}.-</div>
                            <div class="price">Kaufpreis: ${purchasePrice}</div>
                          </div>
                        </td>
                        <td class="flex">
                          <div class="banner"></div>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>

              <!-- END MAIN CONTENT AREA -->
              </table>
              <!-- END CENTERED WHITE CONTAINER -->

              <!-- START FOOTER -->
              <div class="footer">
                <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                  <tr>
                    <td class="content-block">
                      <a href="${WHATSAPP_URL}" target="_blank" class="help">Hilfe?</a>
                    </td>
                  </tr>
                </table>
              </div>
              <!-- END FOOTER -->

            </div>
          </td>
        </tr>
      </table>
    </body>
  </html>`
}
