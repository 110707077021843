import { useEffect, useRef, useState } from 'react'
import { Transition } from 'react-transition-group'

import WhatsAppCard from '../../WhatsAppCard'
import useIsInViewport from '../../../hooks/useIsInViewport'

import styles from './Partners.module.css'

const Partners = () => {
  const [isBoxVisible, setIsBoxVisible] = useState(false)
  const [isBoxClosed, setIsBoxClosed] = useState(false)

  const ref = useRef(null)

  const isInViewport = useIsInViewport(ref)

  useEffect(() => {
    if (!isBoxVisible && !isBoxClosed && isInViewport) setIsBoxVisible(true)
  }, [isInViewport])

  const handleClose = () => {
    setIsBoxClosed(true)
    setIsBoxVisible(false)
  }

  return (
    <section className={styles.wrapper} ref={ref}>
      <h2>Diese Marken empfehlen uns wärmstens.</h2>
      <div className={styles.partners_wrapper}>
        <img src="/domotec.svg" alt="domotec logo" />
        <p>McHeat arbeitet mit der Domotec AG zusammen,einem führenden Hersteller für energieeffiziente Wärmepumpen und Warmwasserlösungen mit einer 40-jährigen Firmengeschichte. Die Domotec AG ist eine hundertprozentige Tochtergesellschaft der italienischen Ariston, einem Weltmarktführer in der Heizungs-und Sanitärtechnik. Zusammen mit dem nationalen Servicenetz von Domotec, das aus unabhängigen Installateuren und Dienstleistern besteht, ist McHeat die perfekte Ergänzung,um Geräte und Service von höchster Qualität, digitale Kundenbetreuung rund um die Uhr underschwingliche Finanzierungsmöglichkeiten in einem Komplettpaket anzubieten.facilisis.</p>
      </div>
      <Transition in={isBoxVisible} timeout={300}>
        {(state) => (
          <WhatsAppCard transitionState={state} handleClose={handleClose} />
        )}
      </Transition>
    </section>
  )
}

export default Partners
