import { WHATSAPP_URL } from '../../constants/whatsApp';
import styles from './McheatHelper.module.css';

export default function McheatHelper() {
    return (
        <div className={styles.container}>
            <div className={styles.smile}></div>
            <a
              href={WHATSAPP_URL}
              target="_blank"
              rel="noreferrer"
              className={styles.helpLink}
            >
              Hilfe?
            </a>
        </div>
    )
}
