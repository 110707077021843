import { FORTY_EIGHT, SIXTY_YEARS, SEVENTY_TWO_YEARS } from '../../constants/operationYears';
import styles from './SettingsThirdStep.module.css';

export default function SettingsThirdStep({ duration, setDuration }) {
  const set48 = () => setDuration(FORTY_EIGHT);
  const set60 = () => setDuration(SIXTY_YEARS);
  const set72 = () => setDuration(SEVENTY_TWO_YEARS);

  return (
    <>
      <div className={styles.outerCircle}>
        <div className={styles.mediumCircle}>
          <div className={styles.innerCircle}>3</div>
        </div>
      </div>
      <h3 className={styles.legend}>Auf welchen Zeitraum willst du die Raten erstrecken?</h3>
      <div className={styles.radioBlock}>
        <div className={styles.radioItem} onClick={set48}>
          <div className={styles.radioBorder}>
            {duration === FORTY_EIGHT && <div className={styles.radioChecked}></div>}
          </div>
          <div>48 Monate</div>
        </div>
        <div className={styles.radioItem} onClick={set60}>
          <div className={styles.radioBorder}>
            {duration === SIXTY_YEARS && <div className={styles.radioChecked}></div>}
          </div>
          <div>60 Monate</div>
        </div>
        <div className={styles.radioItem} onClick={set72}>
          <div className={styles.radioBorder}>
            {duration === SEVENTY_TWO_YEARS && <div className={styles.radioChecked}></div>}
          </div>
          <div>72 Monate</div>
        </div>
      </div>
    </>
  )
}
