import React from 'react'
import Modal from 'react-modal'

import Footer from '../Footer'
import Header from '../Header'

Modal.setAppElement("#root")

const Layout = ({ children }) => {
  return (
    <div className="website_wrapper">
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
