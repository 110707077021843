import React from 'react'
import Select from 'react-select'

import styles from './Controls.module.css'

const Controls = ({
  persons,
  setPersons,
  personsOptions,
  years,
  setYears,
  yearsOptions,
  heat,
  setHeat,
}) => (
  <div className={styles.controls_wrapper}>
    <div className={styles.dropdowns_wrapper}>
      <Select
        className={styles.dropdown}
        value={persons}
        onChange={setPersons}
        options={personsOptions}
        placeholder="Grösse deines Haushalts"
        isSearchable={false}
        components={{ IndicatorSeparator: () => null }}
        instanceId="persons-select"
      />
      <Select
        className={styles.dropdown}
        value={years}
        onChange={setYears}
        options={yearsOptions}
        placeholder="Alter deiner Heizung"
        isSearchable={false}
        components={{ IndicatorSeparator: () => null }}
        instanceId="years-select"
      />
    </div>

    <div className={styles.radio_wrapper}>
      <div className={styles.radio_item} onClick={() => setHeat(0)}>
        <div className={styles.radio_border}>
          {heat === 0 && <div className={styles.radio_checked}></div>}
        </div>
        <div>Gasheizung ersetzen</div>
      </div>
      <div className={styles.radio_item} onClick={() => setHeat(1)}>
        <div className={styles.radio_border}>
          {heat === 1 && <div className={styles.radio_checked}></div>}
        </div>
        <div>Ölheizung ersetzen</div>
      </div>
    </div>
  </div>
)

export default Controls
