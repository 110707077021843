export default function composeEmailOffer(
  vorname,
  name,
  email,
  tel,
  heating_method,
  heater_type,
  duration,
  service_plan,
  purchase_price
) {
  return `<!doctype html>
  <html>
    <head>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
      <title>McHeat offer</title>
      <style>
        img {
          border: none;
          -ms-interpolation-mode: bicubic;
          max-width: 100%;
        }
        body {
          background-color: #80c0ff;
          font-family: sans-serif;
          -webkit-font-smoothing: antialiased;
          font-size: 14px;
          line-height: 1.4;
          margin: 0;
          padding: 0;
          -ms-text-size-adjust: 100%;
          -webkit-text-size-adjust: 100%;
        }
        table {
          border-collapse: separate;
          mso-table-lspace: 0pt;
          mso-table-rspace: 0pt;
          width: 100%;
        }
          table td {
            font-family: sans-serif;
            font-size: 14px;
            vertical-align: top;
        }
        .body {
          background-color: #80c0ff;
          width: 100%;
        }
        .container {
          display: block;
          margin: 0 auto !important;
          max-width: 580px;
          padding: 10px;
          width: 580px;
        }
        .content {
          box-sizing: border-box;
          display: block;
          margin: 0 auto;
          max-width: 580px;
          padding: 10px;
        }
        .main {
          background: #ffffff;
          border-radius: 3px;
          width: 100%;
        }
        .wrapper {
          box-sizing: border-box;
          padding: 20px;
        }
        .content-block {
          padding-bottom: 10px;
          padding-top: 10px;
        }
        .footer {
          clear: both;
          margin-top: 10px;
          text-align: center;
          width: 100%;
        }
        .footer td,
        .footer p,
        .footer span,
        .footer a {
          color: #999999;
          font-size: 12px;
          text-align: center;
        }
        h1,
        h2,
        h3,
        h4 {
          color: #000000;
          font-family: sans-serif;
          font-weight: 400;
          line-height: 1.4;
          margin: 0;
          margin-bottom: 30px;
        }
        h1 {
          font-size: 35px;
          font-weight: 300;
          text-align: center;
          text-transform: capitalize;
        }
        p,
        ul,
        ol {
          font-family: sans-serif;
          font-size: 14px;
          font-weight: normal;
          margin: 0;
          margin-bottom: 15px;
        }
        p li,
        ul li,
        ol li {
          list-style-position: inside;
          margin-left: 5px;
        }
        a {
          color: #3498db;
          text-decoration: underline;
        }
        @media only screen and (max-width: 620px) {
          table.body h1 {
            font-size: 28px !important;
            margin-bottom: 10px !important;
          }
          table.body p,
          table.body ul,
          table.body ol,
          table.body td,
          table.body span,
          table.body a {
            font-size: 16px !important;
          }
          table.body .wrapper,
          table.body .article {
            padding: 10px !important;
          }
          table.body .content {
            padding: 0 !important;
          }
          table.body .container {
            padding: 0 !important;
            width: 100% !important;
          }
          table.body .main {
            border-left-width: 0 !important;
            border-radius: 0 !important;
            border-right-width: 0 !important;
          }
        }
        @media all {
          .apple-link {
            color: black !important;
            font-family: inherit !important;
            font-size: inherit !important;
            font-weight: inherit !important;
            line-height: inherit !important;
            text-decoration: none !important;
          }
          #MessageViewBody a {
            color: inherit;
            text-decoration: none;
            font-size: inherit;
            font-family: inherit;
            font-weight: inherit;
            line-height: inherit;
          }
        }
      </style>
    </head>
    <body>
      <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="body">
        <tr>
          <td>&nbsp;</td>
          <td class="container">
            <div class="content">
  
              <!-- START CENTERED WHITE CONTAINER -->
              <table role="presentation" class="main">
  
                <!-- START MAIN CONTENT AREA -->
                <tr>
                  <td class="wrapper">
                    <table role="presentation">
                      <tr>
                        <td>Name</td>
                        <td>${name}</td>
                      </tr>
                      <tr>
                        <td>Vorname</td>
                        <td>${vorname}</td>
                      </tr>
                      <tr>
                        <td>Email</td>
                        <td>${email}</td>
                      </tr>
                      <tr>
                        <td>TelefonnummerInput</td>
                        <td>${tel}</td>
                      </tr>
                      <tr>
                        <td>Wie wird dein Haus beheizt?</td>
                        <td>${heating_method}</td>
                      </tr>
                      <tr>
                        <td>Welchen Heizungstyp wählst du?</td>
                        <td>${heater_type}</td>
                      </tr>
                      <tr>
                        <td>Auf welchen Zeitraum willst du die Raten erstrecken?</td>
                        <td>${duration}</td>
                      </tr>
                      <tr>
                        <td>Welchen Serviceplan wählst du?</td>
                        <td>${service_plan}</td>
                      </tr>
                      <tr>
                        <td>Purchase price</td>
                        <td>${purchase_price}</td>
                      </tr>
                    </table>
                  </td>
                </tr>
  
              <!-- END MAIN CONTENT AREA -->
              </table>
              <!-- END CENTERED WHITE CONTAINER -->
  
              <!-- START FOOTER -->
              <div class="footer">
                <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                  <tr>
                    <td class="content-block">
                      <span class="apple-link">Mc Heat Musterstrasse 15, 8000 Zürich</span>
                    </td>
                  </tr>
                </table>
              </div>
              <!-- END FOOTER -->
  
            </div>
          </td>
          <td>&nbsp;</td>
        </tr>
      </table>
    </body>
  </html>`
}