import { UNDERFLOOR_HEATING_METHOD, RADIATOR_HEATING_METHOD } from '../../constants/heatingMethods';
import { HEATER_TYPE_WITHOUT_TANK, HEATER_TYPE_WITH_TANK } from '../../constants/heaterTypes';
import styles from './SettingsFirstStep.module.css';

export default function SettingsFirstStep({
    heatingMethod,
    setHeatingMethod,
    heat,
    setHeat
}) {
  const setUnderfloorHeatingMethod = () => setHeatingMethod(UNDERFLOOR_HEATING_METHOD);
  const setRadiatorHeatingMethod = () => {
    setHeatingMethod(RADIATOR_HEATING_METHOD);
    if (heat === HEATER_TYPE_WITHOUT_TANK) {
      setHeat(HEATER_TYPE_WITH_TANK);
    }
  }

  return (
    <>
      <div className={styles.circle}>1</div>
      <h3 className={styles.legend}>Wie wird dein Haus beheizt?</h3>
      <div className={styles.radioBlock}>
        <div className={styles.radioItem} onClick={setUnderfloorHeatingMethod}>
          <div className={styles.radioBorder}>
            {heatingMethod === UNDERFLOOR_HEATING_METHOD
              && <div className={styles.radioChecked}></div>
            }
          </div>
          <div>via Bodenheizung</div>
        </div>
        <div className={styles.radioItem} onClick={setRadiatorHeatingMethod}>
          <div className={styles.radioBorder}>
            {heatingMethod === RADIATOR_HEATING_METHOD
              && <div className={styles.radioChecked}></div>
            }
          </div>
          <div>via Radiatoren</div>
        </div>
      </div>
    </>
  )
}
