import SettingsFirstStep from '../SettingsFirstStep';
import SettingsSecondStep from '../SettingsSecondStep';
import SettingsThirdStep from '../SettingsThirdStep';
import SettingsFourthStep from '../SettingsFourthStep';
import styles from './Settings.module.css';

export default function Settings({
  heatingMethod,
  setHeatingMethod,
  heat,
  setHeat,
  duration,
  setDuration,
  servicePlan,
  setServicePlan
}) {
  return (
    <div className={styles.settings}>
      <div className={styles.settingsStep}>
        <SettingsFirstStep
          heatingMethod={heatingMethod}
          setHeatingMethod={setHeatingMethod}
          heat={heat}
          setHeat={setHeat}
        />
        <SettingsSecondStep
          heat={heat}
          setHeat={setHeat}
          heatingMethod={heatingMethod}
        />
        <SettingsThirdStep
          duration={duration}
          setDuration={setDuration}
        />
        <SettingsFourthStep
          servicePlan={servicePlan}
          setServicePlan={setServicePlan}
        />
      </div>
    </div>
  )
}
