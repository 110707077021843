/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { Link } from "react-router-dom";
import { WHATSAPP_URL } from "../../constants/whatsApp";
import { useLocationContext } from "../../context/LocationContext";

import styles from "./Footer.module.css";

const Footer = () => {
    const context = useLocationContext();
    return (
        <footer className={styles.footer}>
        <div className={styles.main_section}>
          <div>
            <Link to="/" onClick={() => {
                context.setLocation('');
                window.scrollTo(0, 0);
            }}>
              <div>
                <div className={styles.logo}>
                  <img src="/McHeat_Logo.svg" alt="McHeat Logo" />
                </div>
              </div>
            </Link>
          </div>
    
          <div>
            <Link to="/" onClick={() => {
                context.setLocation('');
                window.scrollTo(0, 0);
            }}>
              <div className={`${styles.header} ${styles.column}`}>Home</div>
            </Link>
          </div>
    
          <div>
            <Link to="/config" onClick={() => {
                context.setLocation('#/config');
                window.scrollTo(0, 0);
            }}>
              <div className={`${styles.header} ${styles.column}`}>
                Meine Heizung konfigurieren
              </div>
            </Link>
          </div>
    
          <div className={`${styles.column} ${styles.whatsapp_column}`}>
            <a href={WHATSAPP_URL} target="_blank" className={styles.header}>
              Gespräch starten
            </a>
    
            <a href={WHATSAPP_URL} target="_blank">
              <img
                src="/WhatsApp_Black.svg"
                alt="WhatsApp Logo"
                className={styles.media_icon}
              />
            </a>
          </div>
    
          <div className={styles.column}>
            <span className={styles.header}>Kontakt</span>
    
            <div className={styles.contact_group}>
              <span>McHeat</span>
              <span>Musterstrasse 15,</span>
              <span>8000 Zürich</span>
            </div>
    
            <div className={styles.contact_group}>
              <a href="tel:+410445556677">+41 (0)44 555 66 77</a>
              <a className={styles.email} href="mailto:info@mcheat.ch">
                info@mcheat.ch
              </a>
            </div>
          </div>
    
          <div className={styles.column}>
            <span className={styles.header}>Share & Follow us</span>
    
            <div className={styles.media_links}>
              <a
                href="https://www.facebook.com/McHeat-102120199334451"
                target="_blank"
              >
                <img
                  src="/facebook.svg"
                  alt="Facebook Logo"
                  className={styles.media_icon}
                />
              </a>
              <a href="https://www.instagram.com/mcheat_/" target="_blank">
                <img
                  src="/instagram.svg"
                  alt="Instagram Logo"
                  className={styles.media_icon}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/mc-heat/about/"
                target="_blank"
              >
                <img
                  src="/linkedin.svg"
                  alt="Linkedin Logo"
                  className={styles.media_icon}
                />
              </a>
            </div>
          </div>
        </div>
    
        <div className={styles.bottom_section}>
          <span>&#169; 2022, Alle Rechte vorbehalten.</span>
          <Link href="/">
            <div>Impressum</div>
          </Link>
          <Link href="/">
            <div>AGB</div>
          </Link>
          <a href="/Daten.docx.pdf" target='_blank'>
            <div>Datenschutzerklärung</div>
          </a>
        </div>
      </footer>
    )
};

export default Footer;
