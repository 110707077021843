import styles from './CheckedCheckbox.module.css';

export default function CheckedCheckbox() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.circle}></div>
      <svg
        className={styles.icon}
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="38"
        viewBox="891.768 2530.987 29.232 32.004"
      >
        <path
          d="m893.768 2552.368 9.392 9 19.84-32.004"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="4"
          stroke="#000"
          fill="transparent"
          data-name="Pfad 3047"
        />
      </svg>
    </div>
  )
}
