import Banner from '../MainPage/Banner'
import Description from '../MainPage/Description'
import Benefits from '..//MainPage/Benefits'
import Partners from '../MainPage/Partners'
import Thumbnails from '../MainPage/Thumbnails'
import Savings from '../MainPage/Savings'

export default function HomePage() {
  return (
    <main>
      <Banner />
      <Description />
      <Benefits />
      <Partners />
      <Savings />
      <Thumbnails />
    </main>
  )
}
