import React, { createContext, useContext, useState } from 'react';

const LocationContext = createContext({});

const LocationContextProvider = ({ children }) => {
	const [location, setLocation] = useState(window.location.hash);

	return (
		<LocationContext.Provider
			value={{
				location,
				setLocation,
			}}
		>
			{children}
		</LocationContext.Provider>
	);
};

const useLocationContext = () =>
	useContext(LocationContext);

export { LocationContextProvider, useLocationContext };
