export const personsOptions = [
  { value: 3, label: '2-3 Personen' },
  { value: 4, label: '4 Personen' },
  { value: 5, label: '5+ Personen' },
]

export const yearsOptions = [
  { value: 5, label: '5 Jahre' },
  { value: 10, label: '10 Jahre' },
  { value: 15, label: '15 Jahre' },
  { value: 20, label: '20 Jahre' },
]

export const gasSavings = {
  '3-5': 15357,
  '3-10': 30713,
  '3-15': 46070,
  '3-20': 61427,
  '4-5': 20320,
  '4-10': 40639,
  '4-15': 60959,
  '4-20': 81278,
  '5-5': 23400,
  '5-10': 46801,
  '5-15': 70201,
  '5-20': 93602,
}

export const oilSavings = {
  '3-5': 4827,
  '3-10': 9653,
  '3-15': 14480,
  '3-20': 19307,
  '4-5': 6400,
  '4-10': 12800,
  '4-15': 19201,
  '4-20': 25601,
  '5-5': 6846,
  '5-10': 13692,
  '5-15': 20538,
  '5-20': 27384,
}

export const bubbleOffsets = {
  '3-5': 0.63,
  '3-10': 0.73,
  '3-15': 0.83,
  '3-20': 0.93,
  '4-5': 0.67,
  '4-10': 0.77,
  '4-15': 0.87,
  '4-20': 0.97,
  '5-5': 0.7,
  '5-10': 0.8,
  '5-15': 0.9,
  '5-20': 1,
}

export const MIN_RANGE_VALUE = 0
export const MAX_RANGE_VALUE = 20